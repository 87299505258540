<template>
  <div class="footer">
    <div class="content">
      <div>
        <div>© 2020 {{ webName }} All rights reserved</div>
        <span><a href="javascript:;">Privacy Policy</a> | <a href="javascript:;">Terms of Service</a></span>
      </div>
      <router-link to="home" class="img"><img src="../../assets/img/logo/logo2.png" alt=""></router-link>
    </div>
  </div>
</template>
<script>
export default {
  
  computed: {
    ...Vuex.mapGetters([
      "webName"
    ]),
  }
  
}
</script>
<style lang="stylus" scoped>
.footer{
  position: absolute;
  width: 100%;
  font-size: $Fts5;
  color: $Cf;
  background-color: #000;
  padding: .48rem .86rem;
  box-sizing: border-box;
  text-align: left;
  & > div {
    flex(column-reverse, nowrap);  
  }
  & .content{
    flex(row-reverse, nowrap, space-between, center);
    max-width: 11.4rem;
    padding: 0 .4rem;
    margin: 0 auto;
    margin-top: .18rem;
    & > div{
      padding-left: .24rem;
      text-align: center;
      // border-left: .01rem solid $Cf;  
      & div{
        margin-bottom: .06rem;  
      }
    }
  }
  // height: 1rem;
  & span a {
    color: $Cf;
    margin-top: .1rem;
  }
  & .active{
    font-size: $Fts4;
  }
}
img{
  width: 1rem;
  margin-right: .24rem;
}
</style>