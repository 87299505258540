<template>
  <div :class="[
    'header',
    'flex']">
    <router-link to="home">
      <img :src="logoUrl" />
    </router-link>
    <ul class="flex langEnI">
      <li @click="toContact()">
        <span>COMPANY</span>
      </li>
      <router-link tag="li" to="game">
        <span :class="{ 'check': pageNav == 2 }">GAMES</span>
      </router-link>
    </ul>

  </div>
</template>
<script>
export default {
  data() {
    return {
      checkNav: 0,
    };
  },
  computed: {
    ...Vuex.mapGetters([
      "pageNav",
    ]),
    logoUrl() {
      let url = require('@/assets/img/logo/logo.png');
      return url;
    }
  },
  watch: {
    $route(n, o){
      if (this.$route.name == 'home' && this.$route.query.to === "contactUs") {
        this.toContact()
      }
    }
  },
  mounted() {

  },
  methods: {
    toContact() {
      if (this.$route.name !== 'home') {
        this.$router.push({
          name: 'home',
          query: {
            to: 'contactUs'
          }
        })
        return
      }
      let topNum = document.getElementById("contactUs").offsetTop
      window.scrollTo({
        top: topNum,
        behavior: 'smooth'
      });
    }

  }
};
</script>
<style lang="stylus" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: .96rem;
  font-size: $Fts3;
  padding-left: .86rem;
  box-sizing: border-box;
  background-color: $C0;
  color: $Cf;
  >a {
    line-height: .96rem;
    & img{
      height: .42rem;
      vertical-align: middle;
    }
  }
 
}
ul{
  height: .96rem;
  line-height: .96rem;
  margin-right: 1rem;
  & li{
    padding: 0 .1rem;
    cursor: pointer;
    text-align: center;
    font-family: gotham;
    & span{
      line-height: .2rem;
      display: inline-block;
      padding: .04rem .25rem;
      border-radius.14rem;
      &.check{
        background-color: #4B4B4B;
      }
    }
  }
}


.header.active{
  color: $C0;
  .lang a img{
    filter: brightness(0);
  }
}

</style>